

//Key words 
    // <a href="/categories/girldressupgames" title="princess online games">princess online games</a>.

    //List 
    // <ul class="gamelist">
    // <li class="para description"><a title="BFFs Trendy Squad Fashion" href="/game/bffs-trendy-squad-fashion">BFFs Trendy Squad Fashion</a></li>
    // <li class="para description"><a title="My Romantic Valentine Story" href="/game/my-romantic-valentine-story">My Romantic Valentine Story</a></li>
    // <li class="para description"><a title="Angela Perfect Valentine" href="/game/angela-perfect-valentine">Angela Perfect Valentine</a></li>
    // <li class="para description"><a title="My Best FF Outfits" href="/game/my-best-ff-outfits">My Best FF Outfits</a></li>
    // <li class="para description"><a title="Princesses As Ancient Warriors" href="/game/princesses-as-ancient-warriors">Princesses As Ancient Warriors</a></li>
    // <li class="para description"><a title="Celebrity Signature Styles" href="/game/celebrity-signature-styles">Celebrity Signature Styles</a></li>
    // <li class="para description"><a title="BFFs Trendy Squad Fashion" href="/game/bffs-trendy-squad-fashion">BFFs Trendy Squad Fashion</a></li>
    // <li class="para description"><a title="My Romantic Valentine Story" href="/game/my-romantic-valentine-story">My Romantic Valentine Story</a></li>
    // <li class="para description"><a title="Angela Perfect Valentine" href="/game/angela-perfect-valentine">Angela Perfect Valentine</a></li>
    // <li class="para description"><a title="My Best FF Outfits" href="/game/my-best-ff-outfits">My Best FF Outfits</a></li>
    // <li class="para description"><a title="Princesses As Ancient Warriors" href="/game/princesses-as-ancient-warriors">Princesses As Ancient Warriors</a></li>
    // </ul>

export const PageList = [
  {
    name: "Home",
    title:
      "Play Online Games for Girls at Playcutegames",
    h1: "Play free Cute games at playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/", "https://playcutegames.com"],
    s_dis:
      "Looking for free online games for girls and kids to play? Check out our collection of exciting online dress-up, and makeover games for nonstop entertainment.",
  },
  {
    name: "Privacy",
    title:
      "Privacy Policy - playcutegames.com",
    h1: "Play free Cute games at playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/privacy-policy", "https://playcutegames.com"],
    s_dis:
      "play cute games is providing games for children of all ages. This privacy policy is intended to let you know how we protect your information.",
  },
  {
    name: "Free Games For Your Site",
    title:"Free Games For Your Site - playcutegames.com",
    h1: "Free Games For Your Site at playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/free-games-for-your-site", "https://playcutegames.com"],
    s_dis:
      "We are happy to share our new games to the webmasters through free games for your site page. You can find exciting and fun collection of games for girls.",
  },
  {
    name: "Contact Us",
    title:"Contact Us - playcutegames.com",
    h1: "Contact Us at playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/contact-us", "https://playcutegames.com"],
    s_dis:
      "If you have any questions feel free to contact us and we will answer you as soon as possible! We always love to hear what u think?",
  },
  {
    name: "Our Partners",
    title:"Our Partners - playcutegames.com",
    h1: "Our Partners at playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/our-partners", "https://playcutegames.com"],
    s_dis:
      "play cute games are always on the lookout for long-term partnership and collaboration. Below you can find some of our friends/partners with a cute games.",
  },{
    name: "404 not found",
    title: "404 not found",
    h1: "Our Partners at playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdn-bestgamespot.netlify.app/og-image/bestgamespot.jpg",
    path: ["", ""],
    s_dis:
      " ",
  },
  {
    name: "All Categories",
    title: "All tags",
    h1: "All Categories on playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdn-bestgamespot.netlify.app/og-image/bestgamespot.jpg",
    path: ["/all-categories", "https://playcutegames.com"],
    s_dis:
      "play cute games is providing games for children of all ages. Here you can find all games by tags and cute game collections with pouler games",
  },
  {
    name: "Blogs",
    title: "Blogs",
    h1: "Blogs playcutegames.com",
    h2: "Most popular games of the week ",
    thumb: "https://cdn-bestgamespot.netlify.app/og-image/bestgamespot.jpg",
    path: ["/blog", "https://playcutegames.com"],
    s_dis:
      "play cute games is providing games for children of all ages. Here you can find all games by tags and cute game collections with pouler games",
  },
];

export const Pages = [
  {
    name: "2",
    gamestart:71,
    path: ["/page/2", "https://playcutegames.com"],
  },
  {
    name: "3",
    gamestart:141,
    path: ["/page/3", "https://playcutegames.com"],
  },
];

export const Hint = [
  {
    hint: "Use your left mouse button to select makeup items and outfits",
  },
  {
    hint: "Swipe your finger left-right to move",
  },
  {
    hint: "Click (or Tap) and hold",
  },
];

export const Categories = [
  // Categories
  {
    name: "exclusive-games",
    key: "Exclusive",
    title: "Exclusive Dress Up Games for Girls - playcutegames.com",
    h1: "Exclusive Games",
    h2: "Exclusive Dress Up Games for Girls - playcutegames.com ",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/exclusive-games", "https://playcutegames.com"],
    dis:`
    Do you have a favourite celebrity? Then you will be excited to play our <a href="/categories/celebrity-games" title="celebrity Games">celebrity Games</a> and spend time with your favourite celebrity by dressing them up them. In the games featuring <a href="/game/iconic-celebrity-look" title="Iconic celebrity look">Iconic celebrity look</a> you can dress up the featured character and give a celebrity look to the character. You can also help your favourite characters to make over and choose accessories for their day out. Ariana at Teen Choice Awards is one such game. Our <a href="/categories/celebrity-games" title="celebrity Games">celebrity Games</a> will give you never ending fun and you might wish to come back and play often. Our games will make you creative and help you to learn new things. So feel free to come back and play.`,
    s_dis:
      "Looking for free online games for girls and kids to play? Check out our collection of exciting online dress-up, and makeover games for nonstop entertainment.",
  },
  {
    name: "new-games",
    key: "New games",
    title: "New Games for Kids - playcutegames.com",
    h1: "New Games",
    h2: "New Games for Kids - playcutegames.com ",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/new-games", "https://playcutegames.com"],
    dis:`
    Do you have a favourite celebrity? Then you will be excited to play our <a href="/categories/celebrity-games" title="celebrity Games">celebrity Games</a> and spend time with your favourite celebrity by dressing them up them. In the games featuring <a href="/game/iconic-celebrity-look" title="Iconic celebrity look">Iconic celebrity look</a> you can dress up the featured character and give a celebrity look to the character. You can also help your favourite characters to make over and choose accessories for their day out. Ariana at Teen Choice Awards is one such game. Our <a href="/categories/celebrity-games" title="celebrity Games">celebrity Games</a> will give you never ending fun and you might wish to come back and play often. Our games will make you creative and help you to learn new things. So feel free to come back and play.`,
    s_dis:
      "Looking for free online games for girls and kids to play? Check out our collection of exciting online dress-up, and makeover games for nonstop entertainment.",
  },
  {
    name: "princess-games",
    key: "Princess",
    title: "Play Online Princess Games for Girls - Playcutegames",
    h1: "Princess Games",
    h2: "Online Princess Games for Girls - Crown Your Imagination",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/princess-games", "https://playcutegames.com"],
    dis:` Discover a world of enchantment with our online 
    <a href="/categories/princess-games" title="princess games">princess games</a> for girls. Dive into a realm where fairy tales come alive, and you become the royal ruler of your destiny. Dress up in elegant gowns, solve magical puzzles, and embark on epic adventures in far-off kingdoms. These 
    <a href="/categories/girl-games" title="girl games">girl games</a> let you step into the shoes of a princess and make your dreams of a fairy-tale life a virtual reality.`,
    s_dis:
      "Dive into a world of enchantment with online princess games for girls. Rule kingdoms | dress up royally and let your inner princess shine as you play! 👑",
  },
  {
    name: "dress-up-games",
    key: "Dress up",
    title: "Play Dress Up Games for Girls and Kids - Playcutegames",
    h1: "Dress up Games",
    h2: "Explore Unlimited Dress Up Games for Girls",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/dress-up-games", "https://playcutegames.com"],
    dis:`
    Elevate your style in our  
    <a href="/categories/dress-up-games" title="Dress Up Games for Girls">Dress Up Games for Girls</a>!
    Explore a world of fashion and creativity with celebrity makeovers, princess transformations, and trendy outfit designs. Unleash your inner fashionista and enjoy hours of glamorous fun. Play now and let your imagination shine! Experience the pulse of the fashion world with our dress-up games like 
    "<a href="/game/diana-city-fashion-beauty" title="Dian City Fashion and Beauty">Dian City Fashion and Beauty</a>" and "
    "<a href="/game/raya-multiverse-fashion" title="Raya Multiverse Fashion">Raya Multiverse Fashion</a>." Dive into our
     <a href="/categories/play-cute-games" title="online cute games">online cute games</a> and unleash your hidden creativity. Discover the latest trends and ignite your fashion sense. Join the style adventure now!.
     <h2>FAQ</h2>
    <h3>What is Dress-Up Games?</h3>
    <p>Dress-up is a children's game in which costumes or clothing are put on a person or on a doll, for role-playing or aesthetics purposes. In the UK the game us called dressing up.</p>
    <br>
    <h3>Why do people play dress up games?</h3>
    <p>Dress-up play can help children grow and learn. “Dress-up is an ideal way for young children to work on so many early childhood development skills: literacies, life skills, and creative play</p>
    <br>
    <h3>Are there benefits to playing dress-up? </h3>
    <p>Dress-up encourages creative thinking and communication skills. It also helps kids practice language development and their social skills. Playing with another child or adult requires teamwork, cooperation, and sharing.</p>
    
     `,
    s_dis:
      "Step into a world of fashion and creativity with our Dress Up Games for Girls! Explore endless style possibilities and have fun dressing up your way",
  },
  { 
    name: "makeover-games",
    key: "Make Up",
    title: "Play Exciting Online Makeover Games for Girls - Playcutegames",
    h1: "Makeover Games",
    h2: "Play Fabulous Online Makeover Games for Girls",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/makeover-games", "https://playcutegames.com"],
    dis:`Indulge in a world of glamour and style with online 
    <a href="/categories/makeover-games" title="makeover games">makeover games</a> for girls. Unleash your inner fashionista and play with makeup, hair, and outfits to create stunning looks. From celebrities to fantasy characters, you have a canvas of endless possibilities. Experiment with colors, accessories, and skincare routines. Get ready to transform, have fun, and ignite your creativity with these fabulous makeover 
    <a href="/" title="games for girls">games for girls</a>!`,
    s_dis:
      "Transform into a digital diva with online makeover games for girls. Unleash your inner stylist, paint the web with glam, and play your way to fabulous!",
  },
  {
    name: "decoration-games",
    key: "Decoration",
    title: "Online Decoration Games for Girls - Decorate Your Dreams",
    h1: "Decoration Games",
    h2: "Craft Your Fantasy Realm through Online Decoration Games for Girls",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/decoration-games", "https://playcutegames.com"],
    dis:`For girls who love creativity and design, online 
    <a href="/categories/decoration-games" title="decoration games">decoration games</a> are the perfect playground. Dive into a world of vibrant colors, stylish furniture, and endless possibilities. From designing dream bedrooms to crafting unique outdoor spaces, these 
    <a href="/categories/play-cute-games" title="cute games">cute games</a> allow girls to express their artistic flair. Transform rooms, gardens, and even entire homes into stunning, personalized masterpieces. Let your imagination run wild in the virtual realm of decoration games!`,
    s_dis:
      "Get your creative juices flowing with online decoration games for girls. Design dream spaces and unleash your inner stylist, perfect for girls and kids.",
  },
  {
    name: "wedding-games",
    key: "Wedding",
    title: "Play Online Wedding Games for a Modern Celebration",
    h1: "Wedding Games Online",
    h2: "Play Online Wedding Games for Girls - Level Up Your Love Story",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/wedding-games", "https://playcutegames.com"],
    dis:`Celebrate the magic of love with our enchanting 
    <a href="/categories/wedding-games" title="online wedding games">online wedding games</a> for girls! Dive into a world of bridal bliss and design dreamy dresses, arrange breathtaking flower bouquets, and create the perfect wedding cake. From dress-up challenges to decorating venues, these 
    <a href="/categories/makeover-games" title="makeover games">makeover games</a> let you be the wedding planner of your dreams. Join the fun, and immerse yourself in the world of romance and creativity!`,
    s_dis:
      "Step into a world of romance and fun with online wedding games for girls at PlayCuteGames. Plan dream weddings, design dresses, and more! 💍💐",
  },
  {
    name: "celebrity-games",
    key: "Celebrity",
    title: "Enjoy the Glitz and Glamour with Celebrity Games | Play Now",
    h1: "Celebrity Games",
    h2: "The Ultimate All-Star celebrity games for girls: Playcutegames",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/celebrity-games", "https://playcutegames.com"],
    dis:`Step into the glamorous world of <a href="/categories/celebrity-games">Celebrity Games</a>, where the biggest stars in Hollywood, music, sports, and fashion come to play! This virtual playground is the ultimate destination for fans and girl kids alike, offering a range of exciting dress up challenges and activities that let you live out your wildest fantasies of stardom.`,
    s_dis:
      "Step into the shoes of your favorite celebs with our collection of celebrity games for girls. From fashion to romance, explore the world of fame and fortune",
  },
  {
    // toca boca games, toca boca online, toca boca, toca boca paper doll, toca boca coloring
    
    name: "celebrity-games",
    key: "Celebrity",
    title: "Enjoy the Glitz and Glamour with Celebrity Games | Play Now",
    h1: "Celebrity Games",
    h2: "The Ultimate All-Star celebrity games for girls: Playcutegames",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/celebrity-games", "https://playcutegames.com"],
    dis:`Step into the glamorous world of <a href="/categories/celebrity-games">Celebrity Games</a>, where the biggest stars in Hollywood, music, sports, and fashion come to play! This virtual playground is the ultimate destination for fans and girl kids alike, offering a range of exciting dress up challenges and activities that let you live out your wildest fantasies of stardom.`,
    s_dis:
      "Step into the shoes of your favorite celebs with our collection of celebrity games for girls. From fashion to romance, explore the world of fame and fortune",
  },
  {
    name: "cooking-games",
    key: "Cooking",
    title: "Fun and Flavorful Online Cooking Games for Girls",
    h1: "Cooking Games",
    h2: "Enjoy Delicious Treats with Free Cooking Games for Girls",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/cooking-games", "https://playcutegames.com"],
    dis:`Enter the mouth-watering world of free <h3><a href="/categories/cooking-games">cooking games for girls</a></h3>, where you can unleash your inner chef and create delicious dishes from scratch! With a plethora of games available, you can experiment with different cuisines, learn new cooking techniques, and challenge yourself to become a master chef.
    Immerse yourself in the kitchen, where you'll find an array of fresh ingredients and cooking tools at your fingertips. Slice and dice vegetables, stir sauces to perfection, and sizzle meats on the stove. Whether you're making a hearty pasta dish or a sweet dessert, the possibilities are endless.`,
    s_dis:
      "Welcome to the delicious world of online cooking games for girls! Get ready to sharpen your culinary skills and indulge in some mouth-watering fun!",
  },
  {
    name: "doctor-games",
    key: "Doctor",
    title: "Play Doctor Games for Kids - Playcutegames",
    h1: "Doctor Games",
    h2: "Fun Doctor Games for Kids",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/doctor-games", "https://playcutegames.com"],
    dis:`Explore a delightful world of medical adventures with fun 
    <a href="/categories/doctor-games" title="doctor games">doctor games</a> for kids! These interactive, educational games offer young ones the chance to play doctor, diagnose ailments, perform surgeries, and care for patients in an entertaining and imaginative way. Sparking creativity while teaching valuable lessons about health and empathy.`,
    s_dis:
      "Explore fun and educational doctor games for kids online. Play for free and let your child's imagination and medical skills soar.",
  },
  // Brands
  {
    name: "ufreegames",
    key: "Exclusive",
    title: "Ufreegames for Girls - playcutegames.com",
    h1: "Ufreegames",
    h2: "Ufreegames for Girls - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/ufreegames", "https://playcutegames.com"],
    s_dis:
      "Ufreegames for girls only at playcutegames.com. Play dress up games featuring princesses, superhero violet, baby boss &amp; tulip in best fashion",
  },
  {
    name: "gamezop-games",
    key: "Gamezop",
    title: "Gamezop games - playcutegames",
    h1: "Gamezop games",
    h2: "Gamezop games for Girls - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/gamezop-games", "https://playcutegames.com"],
    s_dis:
      "Gamezop games for kids only at playcutegames.com. Play dress up games featuring princesses, superhero violet, baby boss &amp; tulip in best fashion",
  },
  {
    name: "play-cute-games",
    key: "Exclusive",
    title: "Play Online Cute Games at Playcutegames",
    h1: "Cute Games",
    h2: "Dive into the World of Online Cute Games",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/play-cute-games", "https://playcutegames.com"],
    s_dis:
      "Get ready for an adorable gaming adventure! Play online cute games and immerse yourself in a world of cuteness and fun. Join the fun now!",
    dis:` 
      <p>Dive into a world of adorable fun with our online 
      <a href="/categories/play-cute-games" title="cute games">cute games</a>! 
      From fashion makeovers to magical adventures, our collection promises hours of entertainment. Discover your favorites and let the cuteness overload begin!</p>`,
  },
  {
    name: "girlsgogames",
    key: "GD",
    title: "Get Your Brain Buzzing with Girlsgogames - Playcutegames",
    h1: "Girlsgogames",
    h2: "Escape into a World of Fun with Girlsgogames Online",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/girlsgogames", "https://playcutegames.com"],
    s_dis:
      "Discover a world of fun and fashion at Girlsgogames! Play trendy dress-ups, cooking adventures, and more games, all online, just for you!",
    dis:` 
    Welcome to a world of endless fun of <a href="/categories/girlsgogames" title="GirlsGoGames">GirlsGoGames</a>! Dive into a treasure trove of exciting online games designed just for you. Explore captivating adventures, unleash your creativity, and challenge your skills with a vast selection of 
    <a href="/categories/girl-games" title="girl games">girl games</a>, from dress-up and makeover to brain teasers and thrilling adventures. Whether you're a fashionista or a puzzle pro, GirlsGoGames has something for everyone. Join the gaming community and let the fun begin!
    `,
  },
  {
    name: "dl-girls",
    key: "Dl-Girls",
    title: "Dl-Girls Games for Girls - playcutegames.com",
    h1: "Dl-Girls Games",
    h2: "Dl-Girls Games for Girls - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/dl-girls", "https://playcutegames.com"],
    s_dis:
      "Dl-Girls Games for girls at playcutegames.com. Play dress up games featuring princesses, superhero violet, baby boss &amp; tulip in best fashion",
  },
  {
    name: "dressupwho-games",
    key: "Dressupwho",
    title: "Play Unlimited Dressupwho Games at PlayCuteGames",
    h1: "Dressupwho Games",
    h2: "Dress Up Your Imagination with Dressupwho Games",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/dressupwho-games", "https://playcutegames.com"],
    dis:` Get ready to unleash your inner fashionista with <a href="/categories/dressupwho-games">Dressupwho games</a>  online. This platform is a hub for the best dress up games that cater to every style, occasion, and preference. With Dressupwho, you can step into the shoes of a stylist, designer, or trendsetter and create your own virtual fashion world.
    `,
    s_dis:
      "Step into a world of fashion and creativity with Dressupwho games at Playcutegames. Transform, style, and explore in these captivating dress-up adventures.",
  },
  {
    name: "prinxy-games",
    key: "Prinxy Games",
    title: "Enter a New Dimension of Online Prinxy Games for Girls",
    h1: "Prinxy Games",
    h2: "Level Up Your Entertainment with Prinxy Games Online!",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/prinxy-games", "https://playcutegames.com"],
    dis:`<a href="/categories/prinxy-games" title="Prinxy Games">Prinxy Games</a> is your digital playground for endless fun and creativity! Dive into a vibrant online world crafted just for girls, where imagination knows no bounds. Explore a treasure trove of 
    <a href="/categories/play-cute-games" title="cute games">cute games</a> that cater to your unique tastes, from fashion makeovers to exciting adventures. Connect with friends, share your stylish creations, and unlock your inner gaming superstar. With Prinxy Games, the magic of girl power awaits you at your fingertips! Join the fun today.
    `,
    s_dis:
      "Enjoy the world of endless fun and excitement of Prinxy Games Online! Dive into the best gaming experience at Playcutegames and unleash your inner gamer.",
  },
 
  //Tags
  {
    name: "dino-games",
    key: "Dino",
    title: "Dino Game - Play the Classic Dinosaur Game Online for Free",
    h1: "Dino Game",
    h2: "Play Classic and New Dinosaur Games Online for Free",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/dino-games", "https://playcutegames.com"],
    dis:` Unleash your inner adventurer with our diverse collection of dinosaur games! Whether you're nostalgic for the classic offline dino game or eager to try the latest dino-themed challenges, we’ve got it all.
      
      `,
    s_dis:
      "Explore our Dino games collection, featuring classic and new dinosaur adventures! Play online for free and enjoy endless fun with exciting dino-themed challenges.",
  },
  
  {
    name: "snake-games",
    key: "Snake",
    title: "Snake Games - Play 20 Free Online Snake Games Now!",
    h1: "Snake Games",
    h2: "Snake Games Introduction:",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/snake-games", "https://playcutegames.com"],
    s_dis:
      "Looking for the best snake games? Play 20 fun and challenging snake games online for free. Enjoy classic snake games, multiplayer options, and more—no download required!",
    dis:`<section>
  <p class="P_tag Side_padding2">
    Snake games have been a beloved part of gaming history for decades. Whether you played the classic version on early mobile phones or enjoy the modern multiplayer variations, the core concept remains the same: guide your snake to eat food, grow longer, and avoid crashing. Here, we offer you the ultimate collection of 20 snake games, featuring everything from retro to innovative new takes on this timeless genre.
  </p>

  <h2 class="Sub_Heading">The History of Snake Games</h2>
  <p class="P_tag Side_padding2">
    The first widely recognized snake game appeared in 1976 as "Blockade," a simple two-player arcade game. But it was in 1997 when snake fever hit the world, thanks to the game’s inclusion on Nokia phones. The gameplay was simple but addictive—navigating a constantly moving snake that grew with each piece of food, all while avoiding collisions with the walls or its own tail.
  </p>
  <p class="P_tag Side_padding2">
    This minimalist concept has since evolved into countless variations, keeping the core mechanics intact while introducing new features like multiplayer modes, advanced graphics, and customizable snakes.
  </p>

  <h2 class="Sub_Heading">Why Are Snake Games So Popular?</h2>
  <ul>
    <li><strong>Simplicity:</strong> Snake games are easy to understand and don’t require complicated controls.</li>
    <li><strong>Addictive Gameplay:</strong> The constant challenge of growing your snake while avoiding collisions keeps players coming back for more.</li>
    <li><strong>Quick Fun:</strong> Whether you have a few minutes or a few hours, snake games are perfect for quick entertainment.</li>
    <li><strong>Competition:</strong> Many snake games now feature leaderboards, making it possible to challenge friends or players worldwide.</li>
  </ul>

  <h2 class="Sub_Heading">Types of Snake Games Available</h2>
  
  <div class="Side_padding"> 
  <p class="P_tag">
    In our collection of snake games, we offer a range of styles and challenges to suit all tastes:
  </p>
  <h3 class="Sub_Heading2">1. <a target="_blank" href="/game/color-snake">Classic Snake Games</a></h3>
  <p class="P_tag Side_padding2 Side_padding2">
    If you’re feeling nostalgic, try one of our classic snake games that mimic the original mobile version. Simple graphics and straightforward gameplay make these a great choice for purists.
  </p>
  <h3 class="Sub_Heading2">2. <a target="_blank" href="/game/slithercraft-io">Multiplayer Snake Games</a></h3>
  <p class="P_tag Side_padding2">
    Take on real players from around the world in multiplayer snake games. Battle to be the biggest snake on the field in real-time with exciting power-ups and obstacles.
  </p>
  <h3 class="Sub_Heading2">3. <a target="_blank" href="/game/apple-snake">Snake Puzzle Games</a></h3>
  <p class="P_tag Side_padding2">
    These games mix the traditional snake mechanics with unique puzzles. Navigate through mazes, avoid traps, and reach the goal while growing your snake.
  </p>
  <h3 class="Sub_Heading2">4. <a target="_blank" href="/game/trains-io-3d">3D Snake Games</a></h3>
  <p class="P_tag Side_padding2" class="P_tag Side_padding2">
    For a more immersive experience, try our 3D snake games. These take the classic gameplay to another level with enhanced visuals and dynamic camera angles.
  </p>
  <h3 class="Sub_Heading2">5. <a target="_blank" href="/game/mine-snake">Innovative Snake Games</a></h3>
  <p class="P_tag Side_padding2">
    Snake games have evolved into much more than just avoiding your tail. Our collection includes games that introduce unique mechanics, such as collecting items, fighting other snakes, or navigating new environments.
  </p>
   </div>
  <h2 class="Sub_Heading">How to Play Snake Games</h2>
  <p class="P_tag Side_padding2">The basic controls for snake games are simple:</p>
  <ul>
    <li><strong>Use arrow keys</strong> to move your snake in four directions (up, down, left, right).</li>
    <li><strong>Eat the food</strong> to grow longer.</li>
    <li><strong>Avoid the walls</strong> and don’t run into your own tail!</li>
  </ul>
  <p class="P_tag Side_padding2">
    Some advanced snake games may introduce additional mechanics, such as:
  </p>
  <ul>
    <li><strong>Power-ups</strong> that give you temporary boosts.</li>
    <li><strong>Enemies or obstacles</strong> to avoid.</li>
    <li><strong>Leaderboards</strong> to compete with other players.</li>
  </ul>

  <h2 class="Sub_Heading">Top Snake Games in Our Collection</h2>
  <div class="Side_padding">
  <h3 class="Sub_Heading2">1. <a target="_blank" href="/game/color-snake">Classic Snake</a></h3>
  <p class="P_tag Side_padding2">
    Relive the nostalgic charm of the original snake game. Guide your snake to eat as much food as possible without crashing into the walls or yourself.
  </p>
  <h3 class="Sub_Heading2">2. <a target="_blank" href="/game/snake-zone>Snake Zone</a></h3>
  <p class="P_tag Side_padding2">
    Join a live snake arena and compete against players around the globe. Eat food, grow your snake, and outmaneuver your opponents to be the last one standing.
  </p>
  <h3 class="Sub_Heading2">3. <a target="_blank" href="/game/cube-snake-2048">Cube Snake 2048</a></h3>
  <p class="P_tag Side_padding2">
    Cube Snake 2048 is an exciting game where you guide a snake through a large arena, collect numbered cubes, and merge them to grow bigger. Power-ups boost your snake's abilities for ultimate domination!
  </p>
  <h3 class="Sub_Heading2">4. <a target="_blank" href="/game/clash-of-snakes">Clash of Snakes</a></h3>
  <p class="P_tag Side_padding2">
    An exciting snake game where you navigate through challenging levels with increasing difficulty. Battle other players in a massive arena, customize your snake's appearance, and climb the leaderboard to claim the top spot!
  </p>
  <h3 class="Sub_Heading2">5. <a target="_blank" href="/game/snake-evolution">Snake Evolution</a></h3>
  <p class="P_tag Side_padding2">
    A fun twist on the classic snake game! Control cute animals and snakes, eat other creatures to grow stronger, and dominate the arena. Use simple mechanics to become the strongest predator!
  </p>
  </div>
  <h2 class="Sub_Heading">Frequently Asked Questions</h2>
  <h3 class="Sub_Heading2 Side_padding2">Can I play snake games on mobile?</h3>
  <p class="P_tag Side_padding2">
    Yes! All our snake games are mobile-friendly and can be played on your phone or tablet.
  </p>
  <h3 class="Sub_Heading2 Side_padding2">Do I need to download anything to play these snake games?</h3>
  <p class="P_tag Side_padding2">
    No downloads are required. All snake games are browser-based and can be played instantly.
  </p>
  <h3 class="Sub_Heading2 Side_padding2">Are these snake games free to play?</h3>
  <p class="P_tag Side_padding2">
    Absolutely! All 20 snake games on our site are completely free to play.
  </p>
  <h3 class="Sub_Heading2 Side_padding2 ">Can I compete with friends in snake games?</h3>
  <p class="P_tag Side_padding2">
    Yes! Some of our snake games have multiplayer modes where you can compete with friends or other players online.
  </p>
</section>
`,
    
  },
  
  {
    name: "winx-club-games",
    key: "Winx",
    title: "Enchanting Adventure Awaits - Play Online Winx Club Games!",
    h1: "WinX Club Games",
    h2: "Magic Awaits: Online Winx Club Games",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/winx-club-games", "https://playcutegames.com"],
    dis:` 
    <p>Dive into the enchanting world of magic and fairies with our online <a href="/categories/winx-club-games" title="Winx Club Games" >Winx Club Games</a>! Join Bloom, Stella, and their magical friends on a captivating adventure. Test your skills, solve puzzles, and unlock the power of the Winx. Immerse yourself in a realm of spells and sparkles, all at your fingertips.</p>
  
    <br>
    <h3">What is WinX Club Series?</h3>
    <br><br>
    <p> WinX Club is an animated cartoon series it has 8 seasons. It was created by an Italian animator. The show is set in a magical universe that is inhabited by fairies, witches, and other mythical creatures. The main character is a fairy warrior named bloom, who enrolls at Alfea College to train and hone her skills. Bloom, an ordinary girl, discovers she has magical powers when she is caught up in a fight between a fairy, Stella, and an ogre, Knut. 
    </p>
    <br>
    <B>Characters</B>
    <ul class="gamelist">
    <li class="para description">Bloom</li>
    <li class="para description">Aisha</li>
    <li class="para description">Flora</li>
    <li class="para description">Musa</li>
    <li class="para description">Tecna</li>
    <li class="para description">Stella</li>
    
    </ul>
    
    <br>
    <B>Aisha</B>
    <p>Crown Princess Aisha (Princess Layla in some versions) is the Fairy of Waves. She is the Crown Princess of Andros and its Guardian Fairy. Aisha joins the Winx Club as its sixth member in the second season, and she is an alumna/student of the Alfea College for Fairies.</p>
    <br>
    <B>Flora</B>
    <p> Flora has magical powers based on nature and eventually once reaching a certain age, she went to attend the Alfea school in the Magic Dimension to help a fairy learn to control and evolve their growing powers. Flora became roommates with Bloom, Stella, Musa, Tecna, and later Aisha.</p>
    <br>
    <B>Musa</B>
    <p>Musa is from Melody and currently acts as its Guardian Fairy. She is one of the founding members of the Winx, the fifth Winx girl introduced after Bloom, Stella, Flora, and Tecna, and is an alumna of the Alfea College for Fairies.</p>
    <br>
    <B>Tecna</B>
    <p> Tecna is from Zenith and currently acts as its Guardian Fairy. She is one of the founding members of the Winx Club, the fourth Winx girl introduced after Bloom, Stella, and Flora, and is an alumna of the Alfea College for Fairies.</p>
    <br>
    <B>Stella</B>
    <p> Princess Stella is a fictional character from the animated series Winx Club. She is the second girl in the club to be introduced, the first being Bloom. Her first appearance is in A Fairy in Gardenia.</p>
    
    `,
    s_dis:
      "Dive into a magical realm with Winx Club Games Online! Unleash your inner fairy, solve puzzles, and create enchanting adventures. Join the Winx and win big!",
  },
  {
    name: "fashion-games",
    key: "Fashion",
    title: "Play and Stay Stylish in Online Fashion Games for Girls",
    h1: "Fashion Games",
    h2: "Stylish Fun - Play Fashion Games Online for Girls",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/fashion-games", "https://playcutegames.com"],
    dis:`Step into a vibrant virtual world where creativity knows no bounds! Play 
    <a href="/categories/fashion-games" title="fashion games">fashion games</a> online at PlayCuteGames and unleash your inner fashionista. Tailored for girls who love all things stylish, our fashion games offer a runway of fun. Mix and match outfits, experiment with dazzling accessories, and design your dream closet. Whether you're into dressing up your favorite characters or creating your own fashion line, 
    our collection of <a href="/categories/celebrity-games" title="celebrity games">celebrity games</a> lets you explore endless style possibilities. From haute couture to casual chic, every trend is at your fingertips. Join the fashion frenzy and express your unique style at PlayCuteGames today!
    `,
    s_dis:
      "Step into a virtual runway of style and glamour. Explore Fashion Games Online for Girls at Playcutegames and design your dream fashion world.",
  },
  {
    name: "girl-games",
    key: "Girl games",
    title: "Play Endless Girl Games Online at Playcutegames",
    h1: "Girl Games",
    h2: "Unleash Your Inner Heroine in the World of Online Girl Games",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/girl-games", "https://playcutegames.com"],
    dis:`
    Indulge in hours of entertainment with our collection of free online girl games! Unleash your creativity, fashion sense, and problem-solving skills as you immerse yourself in a world of virtual fun. From dress-up adventures to cooking challenges and thrilling 
    <a href="/categories/makeover-games" title="makeovers">makeovers</a>, our 
    <a href="/" title="online games for girl">online games for girl</a> kids offer endless opportunities to express your style and personality. Invite your friends for a friendly competition or explore exciting solo missions. Whether you're a fashionista, a budding chef, or a puzzle enthusiast, there's a game here for you. Join the excitement and play 
    <a href="/categories/girl-games" title="girl games">girl games</a> online for free today
    `,
    s_dis:
      "Dive into a digital world of endless fun. Explore girl games online - from fashion makeovers to pet care, ignite your imagination and play away.",
  },
  {
    name: "games-for-girls",
    key: "Exclusive",
    title: "Games for Girls - playcutegames.com",
    h1: "Games for Girls",
    h2: "Games for Girls - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/games-for-girls", "https://playcutegames.com"],
    s_dis:
      "Games for girls only at playcutegames.com. Play dress up games featuring princesses, superhero violet, baby boss &amp; tulip in best fashion",
  },
  {
    name: "egirl-games",
    key: "Exclusive",
    title: "Elevate Your Style | Dive into the World of EgirlGames!",
    h1: "Egirlgames",
    h2: "Enter the World of Egirlgames: Where Creativity Meets Style!",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/egirl-games", "https://playcutegames.com"],
    dis:`<p>Welcome to the world of <a href="/categories/egirl-games">Egirlgames</a>, where you can fully embrace your alternative aesthetic and express your unique sense of style! As an eGirl,
     you love to stand out from the crowd with your colorful hair, bold makeup, and edgy fashion choices.</p>
     <p>Unleash your creativity and experiment with different colors and patterns to create the perfect Egirl look. Show off your style in virtual fashion shows and compete with other Egirls to become the ultimate fashion icon.</p>`,
    s_dis:
      "Welcome to the world of Egirlgames, where you can explore the latest trends in e-girl fashion and makeup! Come play with us and let your inner e-girl shine!",
  },
  {
    name: "fnf-gf",
    key: "FNF",
    title: "FNF GF Games for Girls - playcutegames.com",
    h1: "FNF GF Games",
    h2: "FNF GF Games for Girls - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/fnf-gf", "https://playcutegames.com"],
    s_dis:
      "WinX Club Games for girls with a lot of free and attractive WinX Club games only on Playcutegames.  Play the latest WinX Club games with fun and get new Outfits Ideas",
  },
  {
    name: "friday-night-funkin-girlfriend",
    key: "FNF",
    title: "Stay Up All Night with Friday Night Funkin Games",
    h1: "Friday Night Funkin Girlfriend Games",
    h2: "Exploring the Best Friday Night Funkin Games - Step Up Your Rhythm",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/friday-night-funkin-girlfriend", "https://playcutegames.com"],
    dis:`Step into the electrifying world of 
    <a href="/categories/friday-night-funkin" title="Friday Night Funkin Games">Friday Night Funkin Games</a, where rhythm, music, and fun collide! Dive into the groove online and play this addictive musical showdown. Choose your favorite character and challenge foes to epic rap battles. Can you hit the notes and conquer the stage? With catchy tunes and cool visuals, Friday Night Funkin Games promises an unforgettable Friday night gaming experience!`,
    s_dis:
      "Get ready to groove to the beat in Friday Night Funkin games! Join the musical showdown online at Playcutegames for a dance-off like no other.",
  },
  {
    name: "friday-night-funkin",
    key: "FNF",
    title: "Friday Night Funkin - playcutegames.com",
    h1: "Friday Night Funkin Games",
    h2: "Friday Night Funkin - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/friday-night-funkin", "https://playcutegames.com"],
    s_dis:
      "Friday Night Funkin Games for girls with a lot of free and attractive Friday Night Funkin games only on Playcutegames.  Play the latest Friday Night Funkin games with fun and get new Outfits Ideas",
  },
  {
    name: "shopaholic-games",
    key: "Shopaholic",
    title: "Play Shopaholic Games Online for Endless Shopping and Fun",
    h1: "Shopaholic Games ",
    h2: "Shop 'Til You Drop: The Ultimate Online Shopaholic Game",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/shopaholic-games", "https://playcutegames.com"],
    dis:`
    <p>Get ready to indulge in your shopping addiction with our ultimate online <a href="/categories/shopaholic-games">shopaholic game</a>! Immerse yourself in a virtual shopping world where you can explore endless aisles of clothing, accessories, home decor, 
    and more. With a wide variety of shops to choose from, you can fulfill all of your shopping desires without ever leaving your couch.</p>
    `,
    s_dis:
      "Indulge your love for shopping with Shopaholic games online. Enjoy endless fun as you explore virtual malls, dress up your avatar, and shop for fashion items.",
  },
  {
    name: "my-dolphin-show",
    key: "My dolphin show",
    title: "My Dolphin Show - playcutegames.com",
    h1: "My Dolphin Show ",
    h2: "My Dolphin Show - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/my-dolphin-show", "https://playcutegames.com"],
    s_dis:
      "My Dolphin Show for girls with a lot of free and attractive My Dolphin Show only on Playcutegames.  Play the latest My Dolphin Show games with fun and get new Outfits Ideas",
  },
  {
    name: "my-dolphin-show",
    key: "Dolphin",
    title: "My Dolphin Show - playcutegames.com",
    h1: "My Dolphin Show ",
    h2: "My Dolphin Show - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/my-dolphin-show", "https://playcutegames.com"],
    s_dis:
      "My Dolphin Show for girls with a lot of free and attractive My Dolphin Show only on Playcutegames.  Play the latest My Dolphin Show games with fun and get new Outfits Ideas",
  },
  {
    name: "pony-games",
    key: "Pony",
    title: "Pony Games - playcutegames.com",
    h1: "Pony Games ",
    h2: "Pony Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/pony-games", "https://playcutegames.com"],
    s_dis:
      "Pony Games for girls with a lot of free and attractive Pony Games only on Playcutegames.  Play the latest Pony Games games with fun and get new Outfits Ideas",
  },

  {
    name: "match-3-games",
    key: "Match3",
    title: "Match 3 Games - playcutegames.com",
    h1: "Match 3 Games ",
    h2: "Match 3 Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/match-3-games", "https://playcutegames.com"],
    s_dis:
      "Match 3 Games for girls with a lot of free and attractive Matching Games only on Playcutegames.  Play the latest Matching Games with fun and get new Outfits Ideas",
  },
  {
    name: "nail-games",
    key: "Nail",
    title: "Nail Games - playcutegames.com",
    h1: "Nail Games ",
    h2: "Nail Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/nail-games", "https://playcutegames.com"],
    dis:` 
    <p>Nail games are a type of simulation game where players can design and decorate virtual nails. They often involve choosing colors, patterns, and accessories to create unique and creative designs. Some popular examples include <a href="/game/valentine-nail-salon">Valentine Nail Salon </a> and <a href="/game/all-seasons-nail-salon" >All Seasons Nail Salon</a></p>
    <h3>FAQ </h3>
     <h3>What is Nail games?</h3>
     <p>Nail games are virtual games where players can design and decorate virtual nails. They typically simulate the experience of going to a nail salon, allowing players to choose colors, patterns, and accessories to create unique and creative nail designs. The games often include different types of nails, such as fingers, toes, and even animal paws, to decorate. These games can be played on various devices, including smartphones and tablets.</p>
     <h3>How many types of nail games?</h3>
     <p>There are several types of nail games, including:</p>
     
     <ul>
     <li>Nail Salon Games: Players can design and decorate nails in a virtual nail salon, choosing colors, patterns, and accessories to create unique designs.</li>
     <li>Nail Art Games: Similar to nail salon games, these games allow players to design and decorate nails in a virtual environment, but often with a focus on artistic and creative design elements.</li>
     <li>Virtual Manicure Games: These games are focused on simulating the experience of getting a manicure in a virtual setting, allowing players to choose colors and patterns for their nails, as well as apply accessories and jewelry.</li>
     <li>Pet Nail Games: In these games, players can design and decorate the nails of virtual pets, such as dogs and cats.</li>
     </ul>
     <h3>How to play nail games?</h3>
     <p>Playing nail games typically involves following these steps:</p>
     <ul>
     <li>Choose a game: There are many nail games available online, so choose one that you like and that suits your skill level.</li>
     <li>Select a character or hand: Some games allow you to select a character to design and decorate the nails for, while others use a virtual hand.</li>
     </ul>
     `,
    s_dis:
      "Nail games are a type of simulation game where players can design a virtual nails. They often involve choosing colors, patterns, and accessories creative designs.",
  },
  {
    name: "3d-games",
    key: "3D",
    title: "3D Games - playcutegames.com",
    h1: "3D Games ",
    h2: "3D Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/3d-games", "https://playcutegames.com"],
    s_dis:
      "3D Games for girls with a lot of free and attractive 3D Games only on Playcutegames.  Play the latest 3D Games with fun and get new Outfits Ideas",
  },
  {
    name: "girldressupgames",
    key: "Dress up",
    title: "Dive into Girl Dress Up Games Wonderland - Playcutegames",
    h1: "Girl Dress Up Games",
    h2: "Play Enchanting Girl Dress Up Games",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/girldressupgames", "https://playcutegames.com"],
    dis:`
    <p>Get ready to unleash your inner fashionista with 
    <a href="/categories/girldressupgames" title="girl dress up games">girl dress up games</a>. Play these exciting girl 
    <a href="/categories/dress-up-games" title="dress up games">dress up games</a> and explore a world of style and creativity. Mix and match fabulous outfits, experiment with hairstyles, and accessorize to your heart's content. From glamorous red carpet looks to casual everyday fashion, the possibilities are endless. Dive into the virtual closet, express your unique style, and make your fashion dreams come true!
    </p>
   `,
    s_dis:
      "Explore endless fashion fantasies with girl dress up games online. Create stylish outfits | mix | match and let your inner fashionista shine. Play now!",
  },
  {
    name: "mermaid-games",
    key: "Mermaid",
    title: "Mermaid Games - playcutegames.com",
    h1: "Mermaid Games",
    h2: "Mermaid Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/mermaid-games", "https://playcutegames.com"],
    s_dis:
      "Mermaid Games in this you get more fun dressup games and girl games improve your fashion ideas with our top picks Mermaid Games",
  },
  // Doll Tags 
  {
    name: "barbie-dress-up",
    key: "Barbie",
    title: "Barbie dress up - playcutegames.com",
    h1: "Barbie dress up",
    h2: "Barbie dress up - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/barbie-dress-up", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Barbie dress up! You can enjoy the best free online games which are playable on mobile, tablets and PC every day.</p>
    `,
    
    s_dis:
      "Barbie dress up Games in this you get more fun Barbie dress up games and girl games improve your fashion ideas",
  },
  {
    name: "lol-surprise-games",
    key: "LOL Surprise",
    title: "Lol Surprise Games - playcutegames.com",
    h1: "Lol Surprise Games",
    h2: "Lol Surprise Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/lol-surprise-games", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Lol Surprise! You can enjoy the best free online games which are playable on mobile, tablets and PC every day.</p>
    `,
    
    s_dis:
      "Lol Surprise Games in this you get more fun Lol Surprise games and girl games improve your fashion ideas",
  },
  {
    name: "rainbow-high-dolls",
    key: "Rainbow High",
    title: "Rainbow High Dolls - playcutegames.com",
    h1: "Rainbow High dolls Games",
    h2: "Rainbow High Dolls - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/rainbow-high-dolls", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Rainbow High dolls! You can enjoy the best free online games which are playable on mobile, tablets and PC every day.</p>
    `,
    
    s_dis:
      "Rainbow High dolls Games in this you get more fun Rainbow High dolls games and girl games improve your fashion ideas",
  },
  // Docter Tags
  {
    name: "dentist-games",
    key: "Dentist",
    title: "Dentist Games - playcutegames.com",
    h1: "Dentist Games",
    h2: "Dentist Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/dentist-games", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Dentist games! You can enjoy the best free online games which are playable on mobile, tablets and PC every day.</p>
    `,
    
    s_dis:
      "Dentist Games in this you get more fun Dentist games and girl games improve your fashion ideas",
  },

  // Site Tags
  {
    name: "yad-games",
    key: "Yad",
    title: "Yad Games - playcutegames.com",
    h1: "Yad Games",
    h2: "Yad Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/yad-games", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to yad games! You can enjoy the best free online games which are playable on mobile, tablets and PC every day. Our editors choose the most addicing games developed by our partners, such as car games, 3d games, cartoon games, skill games, arcade game, sport games, racing games and many other kinds of fashion games often. You can play all our games on your mobile phone, tablet, pad without download or installation, just visit playcutegames.com in your browser such as safari, chrome, firefox, etc, and then enjoy playing the games.</p>
    `,
    
    s_dis:
      "Yad Games in this you get more fun dressup games and girl games improve your fashion ideas with our top picks Yad Games",
  },
  {
    name: "yiv-games",
    key: "Yiv",
    title: "Yiv Games - playcutegames.com",
    h1: "Yiv Games",
    h2: "Yiv Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/yiv-games", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Yiv games! You can enjoy the best free online games which are playable on mobile, tablets and PC every day. Our editors choose the most addicing games developed by our partners, such as car games, 3d games, cartoon games, skill games, arcade game, sport games, racing games and many other kinds of fashion games often. You can play all our games on your mobile phone, tablet, pad without download or installation, just visit playcutegames.com in your browser such as safari, chrome, firefox, etc, and then enjoy playing the games.</p>
    `,
    
    s_dis:
      "Yiv Games in this you get more fun games and girl games improve your fashion ideas with our top picks Yiv Games",
  },
  {
    name: "poki-games",
    key: "Poki",
    title: "Poki Games - playcutegames.com",
    h1: "Poki Games",
    h2: "Poki Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/poki-games", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Poki games! Here you can enjoy the best free online games which are playable on mobile, tablets and PC every day.</p>
    `,
    
    s_dis:
      "Yad Games in this you get more fun dressup games and girl games improve your fashion ideas with our top picks Yad Games",
  },
  {
    name: "crazy-games",
    key: "Crazy games",
    title: "Crazy Games - playcutegames.com",
    h1: "Crazy Games",
    h2: "Crazy Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/crazy-games", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Crazy games! Here you can enjoy the best free online games which are playable on mobile, tablets and PC every day.</p>
    `,
    
    s_dis:
      "Crazy Games in this you get more fun dressup games and girl games improve your fashion ideas with our top picks Crazy Games",
  },
  {
    name: "lagged-games",
    key: "Lagged",
    title: "Lagged Games - playcutegames.com",
    h1: "Lagged Games",
    h2: "Lagged Games - playcutegames.com",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/yad-games", "https://playcutegames.com"],
    dis:` 
    <p>Hi! Welcome to Lagged games! Here you can enjoy the best free online games which are playable on mobile, tablets and PC every day.</p>
    `,
    
    s_dis:
      "Lagged Games in this you get more fun dressup games and girl games improve your fashion ideas with our top picks Lagged Games",
  },

  // Holiday Tags
  
  {
    name: "christmas-games",
    key: "Christmas",
    title: "Celebrate this Christmas with Exciting Xmas Games Online",
    h1: "Christmas Games Online",
    h2: "Jingle and Play Xmas Games Online Wonderland",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/christmas-games", "https://playcutegames.com"],
    dis:` 
    <p>Get ready to unwrap the ultimate gift of joy for your kids this Christmas! Our online Xmas games are packed with holiday cheer, offering a jingle bell-filled experience that'll keep them entertained all season long. Let the festivities begin with a delightful collection of 
    <a href="/categories/christmas-games" title="Christmas games">Christmas games</a>, ensuring your little ones have a holly, jolly time. Don't miss out on this magical holiday treat! 🎅🎄🎁🔔</p>
    `,
    
    s_dis:
      "Get into the holiday spirit with fun and festive Xmas games online for kids. Christmas fun is just a click away! 🎅🎁🎄🎮",
  },
  {
    name: "halloween-games",
    key: "Halloween",
    title: " Play Special Halloween Games Online - Playcutegames",
    h1: "Halloween Games Online",
    h2: "Spooky Fun Awaits in Halloween Games Online",
    thumb: "https://cdncloud.cutedressup.in/playcutegames/og-image/playcutegames.jpg",
    path: ["/categories/halloween-games", "https://playcutegames.com"],
    dis:` 
    <p> Get your little ghouls and goblins in the spooky spirit with 
    <a href="/categories/halloween-games" title="online halloween games">online halloween games</a> for kids and girls! From virtual costume contests to pumpkin carving challenges, these online games offer a safe and thrilling way for kids to celebrate the season, all from the comfort of home. Enjoy frightful fun together!</p>
    `,
    
    s_dis:
      "Spooky fun awaits! Enjoy Halloween games online. Play, learn, and get into the Halloween spirit from the comfort of your home. 🎃👻🕷️",
  },
];


// 